import { faCommentAltLines, faHashtag, faStream, faTypewriter, faWallet } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm, Form, Row, Col, Input } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TestMessage.module.css";
import useSpinner from '../../../Hooks/useSpinner';
import TYPES from "../../../reducers/types";
import CONSTANTS from "../../../utils/const";
import useAuth from "../../../Hooks/useAuth";
import encodingSevenBits, { cleanErrorMessage, currencyFormat, notificationError, notificationOk } from "../../../utils/helpers";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import HelperButton from "../../HelperButton/HelperButton";
import ReactCountryFlag from "react-country-flag"
import { useEffect } from "react";
import WhatsappApi from "../../../Api/WhatsappApi";
import MobilePreview from "../../../pages/WhatsApp/WhatsAppTemplates/List/MobilePreview";
import { AuthContext } from "../../../contexts/authRedirect";
import BalanceApi from "../../../Api/BalanceApi";

const TestMessage = ({message,
  lang,
  accountId,
  templateName,
  customFields,
  fileHeaders,
  fileData,
  defualtShortLink,
  template,
  messageData,
  campaignMobilePreviewData,
  wabaId,
  canVerifyCost,
  destination,
  setDestination,
  category,
  wabaPhoneId
}) => {

  const [t] = useTranslation("whatsapp-campaign");
  const { auth } =  useAuth();
  const [form] = Form.useForm();
  const { spinnerDispacth } = useSpinner();

  const [isValidateMessage, setIsValidateMessage] = useState(true);
  const [costMessageTest, setCostMessageTest] = useState(0);
  const [phoneError, setPhoneError] = useState(false);
  const [mobilePhone, setMobilePhone] = useState(null);

  const { getMessageCost, sendWhatsAppMessage } = WhatsappApi();
  const { GetAcocuntsBalanceByCompanyId } = BalanceApi();

  const validateMessage = () => {
    form.validateFields()
      .then(values => {

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: true, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
        });

        const { mobileNumber } = values;        

        getMessageCost({ to: mobileNumber, companyId: auth.companyId, category: category })
          .then(response => {
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
            });
    
            setIsValidateMessage(false);
            setDestination({
              countryName: response?.data?.countryName,
              countryCode: response?.data?.countryCode,
              prefix: response?.data?.prefix
            });
            let cost = response?.data?.unitRate * (response?.data?.totalMessage === 0 ? 1 : response?.data?.totalMessage);
            setCostMessageTest(cost);
          }).catch(error => {
            console.error(error);
            
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
            });
            notificationError(t("content-message.notification-error-validation"));
          })
      });
  }

  const testMessage = () => {
    form.validateFields()
      .then(values => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: true, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
        });

        GetAcocuntsBalanceByCompanyId(auth.companyId, auth.accesToken)
          .then(response => {
            const availableBalance = response.find(b => b.accountId === accountId)?.availableBalance || 0
            const { mobileNumber } = values;
            if (availableBalance >= costMessageTest) {
              sendWhatsAppMessage({ ...messageData, wabaPhoneId: wabaPhoneId, accountId: accountId, bodyParameters: campaignMobilePreviewData.bodyExample, companyId: auth.companyId, to: mobileNumber }).then(response => {
                form.resetFields();
                notificationOk(t("content-message.notification-ok-message"));
                setIsValidateMessage(true);
                setDestination(null);
                setCostMessageTest(0);
                spinnerDispacth({
                  type: TYPES.SPINNER.SHOW,
                  payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
                });
              }).catch(error => {
                console.error(error);
                
                spinnerDispacth({
                  type: TYPES.SPINNER.SHOW,
                  payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
                });
      
                notificationError(cleanErrorMessage(error) || t("content-message.notification-error-message"));
              });
            } else {
              notificationError(t("content-message.notification-error-unsufficient-balance-account"));
              spinnerDispacth({
                type: TYPES.SPINNER.SHOW,
                payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
              });
            }
          })
          .catch(error => {
            console.error(error);
            
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
            });
  
            notificationError(cleanErrorMessage(error) || t("content-message.notification-error-message"));
          });
      });
  }

  const validateOnlyNumbers = (_, value) => {
    const regex = /^[0-9]+$/;
    if (value && !regex.test(value)) {
      setPhoneError(true)
      return Promise.reject(new Error(t("phone-validation")));
    }
    setPhoneError(false)
    return Promise.resolve();
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        initialState: false,
      }}
      style={{ display: "flex", width: "100%" }}
    >
      <div style={{ width: "100%" }}>
        <Form.Item
          name="mobileNumber"
          label={t("content-message.label-test-mobile-number")}
          rules={[{ required: true, message: 'Please enter the mobile number' }, { validator: validateOnlyNumbers }]}
        >
          <Input
            placeholder={t("content-message.label-test-mobile-number-placeholder")}
            onChange={e => setMobilePhone(e.target.value)}
          />
        </Form.Item>
        <div className={styles['test-message-info']}>
          {
            destination && (
              <div className={styles['message-information']}>
                <div className={styles['message-information-text']}>
                  <p>{t("content-message.label-test-destination")}</p>
                  <p className={styles['message-information-text-cuntry-value']}>
                    <ReactCountryFlag
                      countryCode={destination?.countryCode}
                      svg
                      style={{
                        width: '1.5em',
                        height: '1.5em',
                        marginRight: 5
                      }}
                      title={destination.countryCode}
                    />
                    +{destination?.prefix} {destination?.countryName}
                  </p>
                </div>
              </div>
            )
          }
          {
            costMessageTest !== 0 && (
              <div className={styles['message-information']}>
                <div className={styles['message-information-text']}>
                  <p>{t("content-message.label-test-message-cost")}</p>
                  <p className={styles['message-information-text-value']}>
                    <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faWallet} /> {currencyFormat(costMessageTest, 4)}
                  </p>
                </div>
              </div>
            )
          }
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            {
              isValidateMessage ? (
                <Button
                  type="secondary"
                  onClick={validateMessage}
                  style={{ width: 210 }}
                  disabled={(mobilePhone && mobilePhone.length <= 5) || !canVerifyCost || phoneError}
                >
                  {t("content-message.button-test-validate")}
                </Button>
              ) : (
                <Popconfirm title={t("content-message.confirm-test-wa")}
                  onConfirm={testMessage}
                  okText={t("content-message.confirm-test-button")}
                  cancelText={t("content-message.cancel-test-button")}
                >
                  <Button
                    type="secondary"
                    style={{ width: 210 }}
                  >
                    {t("content-message.button-test-send")}
                  </Button>
                </Popconfirm>
              )
            }
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <MobilePreview previewValues={{...template, ...campaignMobilePreviewData }} />
      </div>
    </Form>
  );
}
 
export default TestMessage;