const CONSTANTTYPEREPORTLIST = {
  CAMPAIGN: { key: 1, value: "Campaign" },
  ACCOUNT: { key: 2, value: "Account" }
}

const CONSTANPERIODREPORTLIST = {
  YESTERDAY: { key: 1, value: "Yesterday" },
  LASTWEEK: { key: 2, value: "Last Week" },
  LAST30DAYS: { key: 3, value: "Last 30 Days" },
  CURRENTWEEK: { key: 4, value: "Current Week" },
  CURRENTMONTH: { key: 5, value: "Current Month" },
  LASTMONTH: { key: 6, value: "Last Month" },
  CUSTOM: { key: 7, value: "Custom" }
}

const CONSTVOICELIST = {
  MALE_EN: { key: 1, value: "content-message.item-voice-male-en", lan: "en-US", gender: "male", shortName: "en-US-GuyNeural" },
  FEMALE_EN: { key: 2, value: "content-message.item-voice-female-en", lan: "en-US", gender: "female", shortName: "en-US-JennyNeural" },
  MALE_ES: { key: 3, value: "content-message.item-voice-male-es", lan: "es-CO", gender: "male", shortName: "es-CO-GonzaloNeural" },
  FEMALE_ES: { key: 4, value: "content-message.item-voice-female-es", lan: "es-CO", gender: "female", shortName: "es-CO-SalomeNeural" }, 
  MALE_PT: { key: 5, value: "content-message.item-voice-male-pt", lan: "pt-BR", gender: "male", shortName: "pt-BR-AntonioNeural" },
  FEMALE_PT: { key: 6, value: "content-message.item-voice-female-pt", lan: "pt-BR", gender: "female", shortName: "pt-BR-FranciscaNeural" },   
}

const CONSTWHATSAPPTEMPLATESLIST = {
  SHIPPING_EN: { key: 1, value: "Shipping Confirmation en-US", lan: "en_US", templateName: "sample_shipping_confirmation" },
  ISSUE_EN: { key: 2, value: "Issue Resolution en-US", lan: "en_US", templateName: "sample_issue_resolution" },
  SHIPPING_ES: { key: 3, value: "Shipping Confirmation en-ES", lan: "es_ES", templateName: "sample_shipping_confirmation" },
  ISSUE_ES: { key: 4, value: "Issue Resolution en-ES", lan: "es_ES", templateName: "sample_issue_resolution" },  
}

const ROLESLIST = {
  ADMIN: { key: "Admin", value: "Admin" },
  COMPANY_ADMIN: { key: "CompanyAdmin", value: "Company Admin" },
  USER: { key: "user", value: "Account" },
  DEVELOPER: { key: "Developer", value: "Developer" }
}

const CHANELLIST = {
  DEFAULT: { key: -1, value: "All Channels" },
  SMS: { key: 1, value: "Sms" },
  SHORTLINK: { key: 2, value: "Shortlink" },
  VOICE: { key: 3, value: "Voice" },
  RCS: { key: 4, value: "RCS" },
  WHATSAPP: { key: 5, value: "WhatsApp" }
}

const CONSTANTUSERTYPE = {
  CARRIER: { key: 1, value: "Carrier" },
  COMPANY: { key: 2, value: "Company" },
  ACCOUNT: { key: 3, value: "Account" }
}

const CONSTANTS = {
  ROLES: {
    ADMIN: ROLESLIST.ADMIN.key,
    COMPANY_ADMIN: ROLESLIST.COMPANY_ADMIN.key,
    USER: ROLESLIST.USER.key,
    DEVELOPER: ROLESLIST.DEVELOPER.key
  },
  SPINNERITEM: {
    MENU: "MENU",
    ACCOUNTHOME: "ACCOUNTHOME",
    ADDBALANCE: "ADDBALANCE",
    COMPANYACCOUNTS: "COMPANYACCOUNTS",
    ACCOUNTMETRICS: "ACCOUNTMETRICS",
    CREATEACCOUNT: "CREATEACCOUNT",
    VALIDATEMESSAGECAMPAIGN: "VALIDATEMESSAGECAMPAIGN",
    BALANCEPROCCESS: "BALANCEPROCCESS",
    LISTREPORT: "LISTREPORT",
    FILTERREPORTS: "FILTERREPORTS",
    REPORTMESSAGE: "REPORTMESSAGE",
    ACCOUNTBYCOMPANY: "ACCOUNTBYCOMPANY",
    DELETECAMPAIGN: "DELETECAMPAIGN",
    ACCOUNTBALANCEBYACCOUNT: "ACCOUNTBALANCEBYACCOUNT",
    SUMMARY: "SUMMARY",
    SENDCAMPAIGN: "SENDCAMPAIGN",
    COMPANIES: "COMPANIES",
    CREATECOMPANY: "CREATECOMPANY",
    COMPANYRATES: "COMPANYRATES",
    HISTORYBALANCE: "HISTORYBALANCE",
    RATESPROCCESS: "RATESPROCCESS",
    CONFIRMRATES: "CONFIRMRATES",
    SHORTLINKS: "SHORTLINKS",
    USERS: "USERS",
    FEEDBACK: "FEEDBACK",
    CONTACTSSUMMARY: "CONTACTSSUMMARY",
    CONTACTSGROUP: "CONTACTSGROUP",
    CONTACTLIST: "CONTACTLIST",
    DELETEGROUP: "DELETEGROUP",
    DELETECONTACTS: "DELETECONTACTS",
    SAVETEMPLATE: "SAVETEMPLATE",
    DELETETEMPLATE: "DELETETEMPLATE",
    GETAPYKEYS: "GETAPYKEYS",
    ADDAPYKEY: "ADDAPYKEY",
    GETAUDIOMESSAGE: "GETAUDIOMESSAGE",
    GETCAMPAIGNSHISTORY: "GETCAMPAIGNSHISTORY",
    GETCAMPAIGNDETAILS: "GETCAMPAIGNDETAILS",
    GENERATEREPORT: "GENERATEREPORT",
    GETBALANCECOMPANY: "GETBALANCECOMPANY",
    UPDATERATES: "UPDATERATES",
    WEBHOOKURLMO: "WEBHOOKURLMO",
    CREATECONTACT: "CREATECONTACT",
    WABA: "WABA",
    WABATEMPLATES: "WABATEMPLATES",
    CREATEWABA: "CREATEWABA",
    DEFAULT: "DEFAULT",
  },
  DEFAULTVALUES: {
    ALLCOUNTRIES: "All Countries"
  },
  RATES_PROCESS_STEP: {
    SELECT_RATES: "SELECT_RATES",
    SAVE_SELECT_RATES: "SAVE_SELECT_RATES",
    ADD_RATES: "ADD_RATES",
    SAVE_ADD_RATES: "SAVE_ADD_RATES",
    CONFIRM_RATES: "CONFIRM_RATES",
    DONE_RATES: "DONE_RATES",
    RELOAD_RATES: "RELOAD_RATES"
  },
  RATES_TYPE: {
    STANDAR: "Base",
    CUSTOM: "Custom"
  },
  LANGUAGEKEY: "LANGUAGEKEY",
  CAMPAIGNSTATUS: {
    ONHOLD: "On Hold"
  },
  WILDCARD: "@#",
  DIV: "<@#>",
  SHORTLINK: "Shortlink",
  FILETYPES: {
    CSV: {
      NAME: "CSV",
      TYPES: ['application/vnd.ms-excel',
      'application/csv',
      'text/csv']
    },
    EXCEL: {
      NAME: "EXCEL",
      TYPES: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    },
    NONE: "NONE"
  },
  PAGINATION: 50,
  COMPANYDEFAULT: { companyId: "-1", name: "All Companies" },
  ACCOUNTDEFAULT: { accountId: "-1", name: "All Accounts" },
  LIST: {
    DEFAULTVALUE: { key: -1, value: "Selected" },
    PERIOD: [
      { key: 1, value: "Week" },
      { key: 2, value: "Month" },
      { key: 3, value: "Year" }
    ],
    PERIODREPORT: [
      CONSTANPERIODREPORTLIST.YESTERDAY,
      CONSTANPERIODREPORTLIST.LASTWEEK,
      CONSTANPERIODREPORTLIST.LAST30DAYS,
      CONSTANPERIODREPORTLIST.CURRENTWEEK,
      CONSTANPERIODREPORTLIST.CURRENTMONTH,
      CONSTANPERIODREPORTLIST.LASTMONTH,
      CONSTANPERIODREPORTLIST.CUSTOM
    ],
    VOICE: [
      CONSTVOICELIST.FEMALE_EN,
      CONSTVOICELIST.MALE_EN,
      CONSTVOICELIST.FEMALE_ES,
      CONSTVOICELIST.MALE_ES,
      CONSTVOICELIST.FEMALE_PT,
      CONSTVOICELIST.MALE_PT
    ],
    TYPEREPORT: [
      CONSTANTTYPEREPORTLIST.CAMPAIGN,
      CONSTANTTYPEREPORTLIST.ACCOUNT
    ],
    LANGUAGES: [
      { key: "en", value: "En", countriCode: "US"},
      { key: "es", value: "Es", countriCode: "ES" }
    ],
    ROLES: [
      ROLESLIST.COMPANY_ADMIN,
      ROLESLIST.USER,
      ROLESLIST.DEVELOPER
    ],
    CHANELS: [
      CHANELLIST.DEFAULT,
      CHANELLIST.SHORTLINK,
      CHANELLIST.SMS,
      CHANELLIST.VOICE,
      CHANELLIST.RCS,
      CHANELLIST.WHATSAPP
    ],
    WHATSAPPTEMPLATES: [
      CONSTWHATSAPPTEMPLATESLIST.SHIPPING_EN,
      CONSTWHATSAPPTEMPLATESLIST.SHIPPING_ES,
      CONSTWHATSAPPTEMPLATESLIST.ISSUE_EN,
      CONSTWHATSAPPTEMPLATESLIST.ISSUE_ES
    ]
  }
}

export const auth_message_es = "Tu código de verificación es *@#123456@#*. Por tu seguridad, no lo compartas."
export const auth_html_es = "Tu código de verificación es *<div id=\"wildcard_1\" class=\"MessageWildcard_message-area-wildcard__1muQs\" contenteditable=\"false\">123456<button id=\"button_1\" class=\"MessageWildcard_message-area-wildcard-delete__px3Dh\">×</button></div>*. Por tu seguridad, no lo compartas."
export const auth_message_en = "*@#123456@#* is your verification code. For your security, do not share this code."
export const auth_html_en = "*<div id=\"wildcard_1\" class=\"MessageWildcard_message-area-wildcard__1muQs\" contenteditable=\"false\">123456<button id=\"button_1\" class=\"MessageWildcard_message-area-wildcard-delete__px3Dh\">×</button></div>* is your verification code. For your security, do not share this code."
export const auth_header_es = "Bienvenido/a"
export const auth_header_en = "Welcome"
export const auth_footer_es = "Este código caduca en 5 minutos."
export const auth_footer_en = "This code expires in 5 minutes."
export const auth_url = "https://www.whatsapp.com/otp/code/?otp_type=COPY_CODE&code_expiration_minutes=5&code=otp123456"
export const auth_url_text = "Copy code"

export const COLOR_PICKER_COLORS = [
  '#F5222D',
  '#FA8C16',
  '#FADB14',
  '#8BBB11',
  '#52C41A',
  '#13A8A8',
  '#1677FF',
  '#2F54EB',
  '#722ED1',
  '#EB2F96'
]

export default CONSTANTS;
export { CONSTANTTYPEREPORTLIST, CONSTANPERIODREPORTLIST, ROLESLIST, CHANELLIST, CONSTVOICELIST, CONSTANTUSERTYPE }
